import React from 'react'
import "./Rankover.css"

const Rankover = () => {
  return (
    <div className='imgrankdiv'>
      <img src='/images/rankoverlogo.png'/>
    </div>
  )
}

export default Rankover